import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/public/register/`

const saveRegistration = (registration) => {
  return axios.post(api, registration)
}

export default {
  saveRegistration
}

<template>
  <v-card>
    <v-card-text>
      <v-row align="center" class="mb-2">
        <v-col>
          <label class="text-h6">Address</label>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formData.addressLine1"
        :rules="[rules.required]"
        label="Address Line 1"
        maxlength="100"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.addressLine2"
        label="Address Line 2"
        maxlength="100"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.addressLine3"
        label="Address Line 3"
        maxlength="100"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.city"
        label="City"
        maxlength="50"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.region"
        label="County"
        maxlength="50"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.postalCode"
        :rules="[rules.required]"
        label="Postcode"
        maxlength="20"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.country"
        label="Country"
        maxlength="50"
        dense
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    formData: Object,
    rules: Object
  }
}

</script>

<template>
  <v-card>
    <v-card-text>
      <v-row align="center" class="mb-2">
        <v-col>
          <label class="text-h6">Contact</label>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formData.email"
        :rules="rules.emailRules"
        label="E-mail"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.strength]"
        :type="show1 ? 'text' : 'password'"
        label="Password"
        counter
        @click:append="show1 = !show1"
        dense
      ></v-text-field>
      <v-text-field
        block
        v-model="formData.confirmPassword"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, passwordMatch]"
        :type="show1 ? 'text' : 'password'"
        label="Confirm Password"
        counter
        @click:append="show1 = !show1"
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.customerName"
        :rules="[rules.required]"
        label="Business Name"
        maxlength="50"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.firstName"
        :rules="[rules.required]"
        label="First Name"
        maxlength="20"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.lastName"
        :rules="[rules.required]"
        label="Last Name"
        maxlength="20"
        required
        dense
      ></v-text-field>
      <v-text-field
        v-model="formData.phoneNumber"
        :rules="[rules.required]"
        label="Phone"
        maxlength="20"
        required
        dense
      ></v-text-field>
      <!-- <v-select
        v-model="customerGroups"
        :items="groups"
        :menu-props="{ maxHeight: '400' }"
        label="Business Type "
        multiple
        hint="Select all that apply"
        persistent-hint
        dense
      ></v-select> -->
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    formData: Object,
    rules: Object
  },
  data: () => ({
    show1: false,
    customerGroups: [],
    groups: ['Gallery', 'Framer', 'Retail', 'Gift Shop', 'Visitor Attraction']
  }),
  computed: {
    passwordMatch () {
      return () => this.formData.password === this.formData.confirmPassword || 'Password must match'
    }
  }
}
</script>

<template>
  <v-main app>
    <v-container>
      <v-alert
        v-if="submitted"
        class="mt-16"
        border="top"
        colored-border
        type="info"
        elevation="2"
        max-width="500"
      >
        <p>
          Thanks for your registration request. We will review it and get in
          touch shortly.
        </p>
      </v-alert>
      <v-alert
        v-if="error"
        class="mt-16"
        border="top"
        colored-border
        type="warning"
        elevation="2"
      >
        <p v-if="errorMsg === 'DUPLICATE'">
          Looks like we already have you registered as a customer with that
          email address. Please contact us to resolve this.
        </p>
        <p v-if="errorMsg === 'UNKNOWN_ERROR'">
          There's been some kind of problem. Please contact us to resolve this
          quoting error E0001.
        </p>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content> 0131 553 2772 </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <a href="mailto:sales@doricmor.com">sales@doricmor.com</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-card v-if="!submitted" class="mt-3">
        <v-card-title
          class="mb-4 text-xs-center justify-center primary title white--text font-weight-bold"
        >
          Register
        </v-card-title>
        <v-card-text>
          <v-form ref="registerForm" dense v-model="valid" lazy-validation>
            <v-row justify="center">
              <v-col cols="12" md="6" sm="12">
                <contact-details
                  class="mb-2"
                  :rules="rules"
                  :formData="formData"
                ></contact-details>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <billing-details
                  :rules="rules"
                  :formData="formData"
                ></billing-details>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="6" sm="12">
                <v-btn
                  block
                  :disabled="!valid"
                  color="success"
                  @click="register"
                  >Register</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import ContactDetails from "./RegisterContact.vue";
import BillingDetails from "./RegisterBilling.vue";
import RegisterApi from "@/api/register";
import zxcvbn from "zxcvbn";

export default {
  metaInfo: {
    title: "Register for a trade account"
  },
  components: {
    ContactDetails,
    BillingDetails
  },
  props: {
    value: Boolean,
  },
  computed: {
    passwordMatch() {
      return () =>
        this.formData.password === this.formData.confirmPassword ||
        "Password must match";
    },
  },
  methods: {
    register() {
      if (this.$refs.registerForm.validate()) {
        RegisterApi.saveRegistration(this.formData)
          .then((data) => {
            this.submitted = true;
            this.error = false;
          })
          .catch((err) => {
            this.error = true;
            this.errorMsg = err.response.data.msg;
          });
      }
    },
    cancel() {
      this.$emit("closeDialog");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  data: () => ({
    formData: { accessRoles: ["CUSTOMER"] },
    submitted: false,
    error: false,
    errorMsg: null,
    dialog: true,
    tab: 0,
    tabs: [
      { name: "Login", icon: "mdi-account" },
      { name: "Register", icon: "mdi-account-outline" },
    ],
    valid: true,
    rules: {
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      required: (value) => !!value || "Required.",
      strength: (v) =>
        (v && zxcvbn(v).score >= 3) ||
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",
    },
  }),
};
</script>
<style scoped>
::v-deep .v-form .v-input {
  padding: 0px;
}
::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-clip: text;
}
</style>
